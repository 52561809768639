import axios from 'axios'

const IndustryService = {

  async init () {
    return axios({
      url: axios.defaults.baseURL + 'industry/init',
      method: 'GET'
    })
  },

  async load (data) {
    return axios({
      url: axios.defaults.baseURL + 'industry/load',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async loadCompanies (data) {
    return axios({
      url: axios.defaults.baseURL + 'industry/loadcompanies',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async generateReport (data) {
    return axios({
      url: axios.defaults.baseURL + 'industry/generateReport',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async save (data) {
    return axios({
      url: axios.defaults.baseURL + 'industry/save',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async delete (id) {
    return axios({
      url: axios.defaults.baseURL + 'industry/delete',
      method: 'POST',
      data: JSON.stringify({ id: +id })
    })
  }
}

export default IndustryService
