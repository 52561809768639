<template>
  <b-card
    no-body
    class="mt-3"
    v-if="companyselections"
  >
    <b-card-header>
      <b-card-title class="text-left m-0"><b-form-checkbox v-model="selected" value="selected" unchecked-value="unselcted">Available Companies</b-form-checkbox></b-card-title>
    </b-card-header>

    <b-table
      :items="companyselections"
      :fields="fields"
      outlined
      small
      striped
      hover
      class="card-body mb-0"
    >
      <template #cell(id)="data">
        <b-form-checkbox
          v-model="data.item.selected"
          :disabled="loading || isProcessing"
          @input="$emit('update', companyselections)"
        ></b-form-checkbox>
      </template>
      <template #cell(income)="data">
        {{ data.item.income | currency }}
      </template>
      <template #cell(expense)="data">
        <a
          v-if="data.item.details && data.item.details.length"
          href=""
          @click.prevent="showExpenseDetail(data.item)"
        >
          <b-icon-info-square-fill variant="info"></b-icon-info-square-fill>
        </a>
        {{ Math.abs(data.item.expense) | currency }}
      </template>
      <template #cell(net)="data">
        {{ data.item.net | currency }}
      </template>
      <template #cell(netpct)="data">
        {{ data.item.netpct }}%
      </template>
    </b-table>

    <b-modal
      v-model="showExpenseDetailModal"
      v-if="selectedDetail"
      size="md"
      :title="'Expense Detail for ' + selectedDetail.company"
      no-close-on-backdrop
      no-close-on-esc
      @ok="selectedDetail = null"
      ok-only
    >
      <b-table-simple>
        <b-thead>
          <b-th>Account</b-th>
          <b-th class="text-right">Amount</b-th>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="d in selectedDetail.details"
            :key="d.name"
          >
            <b-td>{{ d.name }}</b-td>
            <b-td class="text-right">{{ Math.abs(d.balance) | currency }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>
  </b-card>
</template>
<script>
export default {
  name: 'AvailableCompanies',

  props: {
    companies: {
      type: Array,
      default () { return [] }
    },

    isProcessing: {
      type: Boolean,
      default () { return false }
    }
  },

  data () {
    return {
      loading: false,
      selected: null,
      companyselections: [],
      showExpenseDetailModal: false,
      selectedDetail: null,
      fields: [
        { key: 'id', label: '', sortable: false, tdClass: 'align-middle' },
        { key: 'company', sortable: true, thClass: 'text-left', tdClass: 'align-middle text-left' },
        { key: 'income', sortable: true, thClass: 'text-right', tdClass: 'align-middle text-right' },
        { key: 'expense', sortable: true, thClass: 'text-right', tdClass: 'align-middle text-right' },
        { key: 'net', sortable: true, thClass: 'text-right', tdClass: 'align-middle text-right' },
        { key: 'netpct', label: '%', sortable: true, thClass: 'text-right', tdClass: 'align-middle text-right' }
      ]
    }
  },

  watch: {
    selected (n, o) {
      if (n !== o) {
        if (n === 'selected') {
          if (this.companies && this.companies.length) {
            this.companies.forEach((c) => {
              c.selected = true
            })
          }
        } else {
          if (this.companies && this.companies.length) {
            this.companies.forEach((c) => {
              c.selected = false
            })
          }
        }
      }
    }
  },

  mounted () {
    if (this.companies && this.companies.length) {
      this.companies.forEach((c) => {
        c.selected = false
        this.companyselections.push(c)
      })
    }
  },

  methods: {
    showExpenseDetail (company) {
      this.selectedDetail = company
      this.showExpenseDetailModal = true
    }
  }
}
</script>
